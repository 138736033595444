import React from "react";
import styled from "styled-components";
// Components
import ProjectBox from "../Elements/ProjectBox";
// import FullButton from "../Buttons/FullButton";
// Assets
import ProjectImg1 from "../../assets/img/projects/1.png";
import ProjectImg2 from "../../assets/img/projects/2.png";
import ProjectImg3 from "../../assets/img/projects/3.png";
import ProjectImg4 from "../../assets/img/projects/4.png";
import ProjectImg5 from "../../assets/img/projects/5.png";
import ProjectImg6 from "../../assets/img/projects/6.png";
// import AddImage2 from "../../assets/img/add/add2.png";
import SAPOTA from "../../assets/img/add/sapota.png";


export default function Projects() {

  return (
    <Wrapper id="projects">
      <div className="whiteBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Our Awesome Products</h1>
            {/* <p className="font13">
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut
              <br />
              labore et dolore magna aliquyam erat, sed diam voluptua.
            </p> */}
          </HeaderInfo>
          <div className="row textCenter">
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                img={ProjectImg1}
                title="Sapota - Chickoo"
                text="Sapota - Chickoo has a grainy texture and a mildly musky flavour. The soft fleshy fruit is scooped out to make smoothies, jams and delectable desserts."
                action={() => alert("clicked")}
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                img={ProjectImg2}
                title="Kesar Mango"
                text="Appearance: Kesar mangoes are oval-shaped, medium-sized fruits with yellowish-green skin that turns golden yellow when ripe. The flesh is bright orange. 
Taste: Kesar mangoes are sweet and juicy with a delicate sweetness and a hint of floral notes. 
Nutrition: Kesar mangoes are rich in vitamins A and C, iron, folate, and magnesium"
                action={() => alert("clicked")}
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                img={ProjectImg3}
                title="Strawberries"
                text="Extremely juicy and syrupy, these conical heart shaped fruits have seeds on the skin that give them a unique texture. With a blend of sweet-tart flavour, these are everyone's favourite berries."
                action={() => alert("clicked")}
              />
            </div>
          </div>
          <div className="row textCenter">
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                img={ProjectImg4}
                title="Spinach"
                text="Spinach is a nutritious leafy, green vegetable that may benefit skin, hair, and bone health. Additionally, this vegetable provides minerals and vitamins that can confer a range of benefits."
                action={() => alert("clicked")}
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                img={ProjectImg5}
                title="Green chillies"
                text="Fresh organically grown green chillies are meant to send shivers up your spine - literally! These chillies are grown in special organic farms, from where they are packaged in hygienic and clean environments to ensure purity. "
                action={() => alert("clicked")}
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                img={ProjectImg6}
                title="Capsicum"
                text="Green capsicum, known as bell pepper, is a versatile vegetable widely used in Indian cuisine. Its crisp texture and mild, slightly bitter flavour make it a favourite in curries, stir-fries, and salads across the country. "
                action={() => alert("clicked")}
              />
            </div>
          </div>
          {/* <div className="row flexCenter">
            <div style={{ margin: "50px 0", width: "200px" }}>
              <FullButton title="Load More" action={() => alert("clicked")} />
            </div>
          </div> */}
        </div>
      </div>
      <div className="lightBg">
        <div className="container">
          <Advertising className="flexSpaceCenter">
            <AddLeft>
              <AddLeftInner>
                <ImgWrapper className="flexCenter">
                  <img className="radius8" src={SAPOTA} alt="add" />
                </ImgWrapper>
              </AddLeftInner>
            </AddLeft>
            <AddRight>
              <h4 className="font15 semiBold">A few words about Sapota - Chikoo</h4>
              <h2 className="font40 extraBold">Sapota - Chikoo  Banifits</h2>
              <p className="font16">
                Sapota is high on calories providing 83 calories per 100 grams. A good source of dietary fibre,
                the pulp of this fruit functions as an excellentlaxative.
                It is loaded with a rich array of vitamins A, C, niacin, folate and pantothenic acid and minerals iron, potassium and copper.
                The host of plant compound tannins in sapota possesses strong antioxidant, anti-inflammatory, antiviral, antibacterial and antiparasitic effects.
                The plethora of essential nutrients in sapota promotes overall health and well-being.
              </p>
              <hr></hr>

              <h2 className="font20">FOB  /  CIF Rates</h2>
              <br></br>
              <table style={{ width: "100%" }}>
                <tr>
                  <td style={{fontStyle:"bold"}}>FOB Price:</td>
                  <td>US$1.4 - US$1.6 Per Kg</td>
                </tr>
                <tr>
                  <td>Min.Order Quantity:</td>
                  <td>500 Kg</td>
                </tr>
                <tr>
                  <td>Supply Ability:	</td>
                  <td>1000 Kg Per Week</td>
                </tr>
                <tr>
                  <td>Port:</td>
                  <td>JNPT Mumbai / Hazira Surat</td>
                </tr>
                <tr>
                  <td>Payment Terms:</td>
                  <td>40% Advanced TT && 60% Advanced on BL</td>
                </tr>
              </table>
              

              {/* <ButtonsRow className="flexNullCenter" style={{ margin: "30px 0" }}>
                <div style={{ width: "190px" }}>
                  <FullButton title="Get Started" action={() => alert("clicked")} />
                </div>
                <div style={{ width: "190px", marginLeft: "15px" }}>
                  <FullButton title="Contact Us" action={() => alert("clicked")} border />
                </div>
              </ButtonsRow> */}
            </AddRight>
          </Advertising>
        </div>
      </div>


      {/* <div className="lightBg">
        <div className="container">
          <Advertising className="flexSpaceCenter">
            <AddLeft>
              <h4 className="font15 semiBold">A few words about Sapota - Chikoo</h4>
              <h2 className="font40 extraBold">Sapota - Chikoo  Banifits</h2>
              <p className="font16">
                Sapota is high on calories providing 83 calories per 100 grams. A good source of dietary fibre,
                the pulp of this fruit functions as an excellentlaxative.
                It is loaded with a rich array of vitamins A, C, niacin, folate and pantothenic acid and minerals iron, potassium and copper.
                The host of plant compound tannins in sapota possesses strong antioxidant, anti-inflammatory, antiviral, antibacterial and antiparasitic effects.
                The plethora of essential nutrients in sapota promotes overall health and well-being.
              </p>
              <hr></hr>

              <h2 className="font20">Sapota - Chikoo  Banifits</h2>
              <h2 className="font20">Sapota - Chikoo  Banifits</h2>
              <ButtonsRow className="flexNullCenter" style={{ margin: "30px 0" }}>
                <div style={{ width: "190px" }}>
                  <FullButton title="Get Started" action={() => alert("clicked")} />
                </div>
                <div style={{ width: "190px", marginLeft: "15px" }}>
                  <FullButton title="Contact Us" action={() => alert("clicked")} border />
                </div>
              </ButtonsRow>
            </AddLeft>
            <AddRight>
                <ImgWrapper className="flexCenter">
                  <img className="radius8" src={SAPOTA} alt="add" />
                </ImgWrapper>
            </AddRight>
          </Advertising>
        </div>
      </div> */}


    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Advertising = styled.div`
  padding: 100px 0;
  margin: 100px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 60px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;
// const ButtonsRow = styled.div`
//   @media (max-width: 860px) {
//     justify-content: space-between;
//   }
// `;
const AddLeft = styled.div`
  position: relative;
  width: 50%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddRight = styled.div`
  width: 50%;
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
  }
`;
const AddLeftInner = styled.div`
  width: 100%;
  position: absolute;
  top: -300px;
  left: 0;
  @media (max-width: 1190px) {
    top: -250px;
  }
  @media (max-width: 920px) {
    top: -200px;
  }
  @media (max-width: 860px) {
    order: 1;
    position: relative;
    top: -60px;
    left: 0;
  }
`;
const ImgWrapper = styled.div`
  width: 100%;
  padding: 0 15%;
  img {
    width: 100%;
    height: auto;
  }
  @media (max-width: 400px) {
    padding: 0;
  }
`;
